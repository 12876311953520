var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"animationSection",staticClass:"animation-section"},[_c('div',{staticClass:"static-elements"},[_vm._l((_vm.orangeCircles),function(circle,index){return _c('div',{key:'circle1-' + index,staticClass:"static-element circle-element",class:'circle-element--1',style:({
        width: `calc(${circle.size} / 1366 * 100%)`,
        paddingBottom: `calc(${circle.size} / 1366 * 100%)`,
        left: `calc(${circle.left} / 1366 * 100%)`,
        top: `calc(${circle.top} / 887 * 100%)`,
        backgroundColor: circle.color
      })})}),_vm._v(" "),_vm._l((_vm.greenCircles),function(circle,index){return _c('div',{key:'circle2-' + index,staticClass:"static-element circle-element",class:'circle-element--2',style:({
        width: `calc(${circle.size} / 1366 * 100%)`,
        paddingBottom: `calc(${circle.size} / 1366 * 100%)`,
        left: `calc(${circle.left} / 1366 * 100%)`,
        top: `calc(${circle.top} / 887 * 100%)`,
        backgroundColor: circle.color
      })})}),_vm._v(" "),_vm._l((_vm.flowers),function(flower,index){return _c('img',{key:'flower-' + index,staticClass:"static-element flower-element",style:({
        width: `calc(${flower.size} / 1366 * 100%)`,
        height: `calc(${flower.size} / 887 * 100%)`,
        left: `calc(${flower.left} / 1366 * 100%)`,
        top: `calc(${flower.top} / 887 * 100%)`,
        transform: `rotate(${flower.rotate || 0}deg)`
      }),attrs:{"src":flower.src}})})],2),_vm._v(" "),_c('div',{ref:"animationContainer",staticClass:"flower-animation"},[_c('div',{staticClass:"flower",class:{ 'is-animated': _vm.isVisible }},[_c('img',{staticClass:"flower__petal flower__petal--1",attrs:{"src":"/assets/images/cms/tasty-job/petal1.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__petal flower__petal--2",attrs:{"src":"/assets/images/cms/tasty-job/petal2.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__petal flower__petal--3",attrs:{"src":"/assets/images/cms/tasty-job/petal3.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__petal flower__petal--4",attrs:{"src":"/assets/images/cms/tasty-job/petal4.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__petal flower__petal--5",attrs:{"src":"/assets/images/cms/tasty-job/petal5.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__petal flower__petal--6",attrs:{"src":"/assets/images/cms/tasty-job/petal6.svg"}}),_vm._v(" "),_c('img',{staticClass:"flower__center",attrs:{"src":"/assets/images/cms/tasty-job/center.svg"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }