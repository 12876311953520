<template>
  <div class="animation-section" ref="animationSection">
    <div class="static-elements">
      <div
        v-for="(circle, index) in orangeCircles"
        :key="'circle1-' + index"
        class="static-element circle-element"
        :class="'circle-element--1'"
        :style="{
          width: `calc(${circle.size} / 1366 * 100%)`,
          paddingBottom: `calc(${circle.size} / 1366 * 100%)`,
          left: `calc(${circle.left} / 1366 * 100%)`,
          top: `calc(${circle.top} / 887 * 100%)`,
          backgroundColor: circle.color
        }"
      />
      <div
        v-for="(circle, index) in greenCircles"
        :key="'circle2-' + index"
        class="static-element circle-element"
        :class="'circle-element--2'"
        :style="{
          width: `calc(${circle.size} / 1366 * 100%)`,
          paddingBottom: `calc(${circle.size} / 1366 * 100%)`,
          left: `calc(${circle.left} / 1366 * 100%)`,
          top: `calc(${circle.top} / 887 * 100%)`,
          backgroundColor: circle.color
        }"
      />
      <img
        v-for="(flower, index) in flowers"
        :key="'flower-' + index"
        :src="flower.src"
        class="static-element flower-element"
        :style="{
          width: `calc(${flower.size} / 1366 * 100%)`,
          height: `calc(${flower.size} / 887 * 100%)`,
          left: `calc(${flower.left} / 1366 * 100%)`,
          top: `calc(${flower.top} / 887 * 100%)`,
          transform: `rotate(${flower.rotate || 0}deg)`
        }"
      />
    </div>
    <div class="flower-animation" ref="animationContainer">
      <div class="flower" :class="{ 'is-animated': isVisible }">
        <img src="/assets/images/cms/tasty-job/petal1.svg" class="flower__petal flower__petal--1" />
        <img src="/assets/images/cms/tasty-job/petal2.svg" class="flower__petal flower__petal--2" />
        <img src="/assets/images/cms/tasty-job/petal3.svg" class="flower__petal flower__petal--3" />
        <img src="/assets/images/cms/tasty-job/petal4.svg" class="flower__petal flower__petal--4" />
        <img src="/assets/images/cms/tasty-job/petal5.svg" class="flower__petal flower__petal--5" />
        <img src="/assets/images/cms/tasty-job/petal6.svg" class="flower__petal flower__petal--6" />
        <img src="/assets/images/cms/tasty-job/center.svg" class="flower__center" />
      </div>
    </div>
  </div>
</template>

<script>
const flowerImagePath = '/assets/images/cms/tasty-job/flower.svg';
const orangeColor = '#FF6000';
const greenColor = '#B4E103';

export default {
  data () {
    return {
      isVisible: false,
      orangeCircles: [
        { size: 26, left: 209, top: 272, color: orangeColor },
        { size: 16, left: 288, top: 456, color: orangeColor },
        { size: 26, left: 1257, top: 466, color: orangeColor },
        { size: 16, left: 1073, top: 635, color: orangeColor }
      ],
      greenCircles: [
        { size: 24, left: 183, top: 138, color: greenColor },
        { size: 10, left: 80, top: 352, color: greenColor },
        { size: 24, left: 52, top: 541, color: greenColor },
        { size: 24, left: 1240, top: 285, color: greenColor },
        { size: 10, left: 1261, top: 704, color: greenColor }
      ],
      flowers: [
        {
          size: 88.6,
          left: 21,
          top: 106,
          src: flowerImagePath,
          rotate: -19.29
        },
        {
          size: 56,
          left: 165,
          top: 444,
          src: flowerImagePath,
          rotate: 0
        },
        {
          size: 124.22,
          left: 80,
          top: 635,
          src: flowerImagePath,
          rotate: 0
        },
        {
          size: 124.22,
          left: 1304,
          top: 93,
          src: flowerImagePath,
          rotate: 0
        },
        {
          size: 56,
          left: 1096,
          top: 373,
          src: flowerImagePath,
          rotate: 0
        },
        {
          size: 88.6,
          left: 1136,
          top: 754.27,
          src: flowerImagePath,
          rotate: -19
        }
      ]
    }
  },
  mounted () {
    const options = {
      root: null,
      threshold: 0.6
    }
    // Використовуємо Intersection Observer для запуску анімації при появі в viewport
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.isVisible = true;
        observer.disconnect();
      }
    }, options);

    observer.observe(this.$refs.animationContainer);
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.animation-section {
  position: relative;
  width: 100%;
  max-width: 1366px;
  aspect-ratio: 1366 / 887;
  margin: 0 auto;
}

.static-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.static-element {
  position: absolute;
}

.circle-element {
  position: absolute;
  border-radius: 50%;
  height: 0;
}

.flower-element {
  transform-origin: center; // центр обертання
  transition: transform 0.3s ease; // опціонально: плавне обертання при зміні
}

.flower-animation {
  width: 100%;
  max-width: 790px;
  aspect-ratio: 790 / 754; // Зберігаємо пропорції
  margin: 0 auto;
}

.flower {
  position: relative;
  width: 100%;
  height: 100%;

  &__center {
    position: absolute;
    width: calc(303.3 / 790 * 100%); // ~38.39%
    height: calc(303.95 / 754 * 100%); // ~40.31%
    opacity: 0;
    transform-origin: center;
    left: calc(187.9 / 790 * 100%); // ~23.78%
    top: calc(491.01 / 754 * 100%); // ~65.12%
    transform: rotate(76.82deg) scale(0.8);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__petal {
    position: absolute;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;

    &--1 {
      width: calc(221.93 / 790 * 100%); // ~28.09%
      height: calc(247.2 / 754 * 100%); // ~32.78%
      left: calc(-4.98 / 790 * 100%); // ~-0.63%
      top: calc(406.8 / 754 * 100%); // ~53.95%
      transform: rotate(76.82deg) scale(0.8);
    }

    &--2 {
      width: calc(275.78 / 790 * 100%); // ~34.88%
      height: calc(275.5 / 754 * 100%); // ~36.54%
      left: calc(141.98 / 790 * 100%); // ~18.22%
      top: calc(243.14 / 754 * 100%); // ~32.24%
      transform: rotate(76.82deg) scale(0.8);
    }

    &--3 {
      width: calc(351.93 / 790 * 100%); // ~44.55%
      height: calc(332.23 / 754 * 100%); // ~44.06%
      left: calc(339.61 / 790 * 100%); // ~42.99%
      top: calc(329.27 / 754 * 100%); // ~43.67%
      transform: rotate(76.82deg) scale(0.8);
    }

    &--4 {
      width: calc(304.16 / 790 * 100%); // ~38.5%
      height: calc(303.67 / 754 * 100%); // ~39.98%
      left: calc(423.16 / 790 * 100%); // ~53.56%
      top: calc(553.58 / 754 * 100%); // ~73.42%
      transform: rotate(76.82deg) scale(0.8);
    }

    &--5 {
      width: calc(364.24 / 790 * 100%); // ~46.11%
      height: calc(345.65 / 754 * 100%); // ~45.84%
      left: calc(238.89 / 790 * 100%); // ~29.99%
      top: calc(755.61 / 754 * 100%); // ~100.21%
      transform: rotate(76.82deg) scale(0.8);
    }

    &--6 {
      width: calc(284.17 / 790 * 100%); // ~35.97%
      height: calc(277.66 / 754 * 100%); // ~36.8%
      left: calc(39.67 / 790 * 100%); // ~5.02%
      top: calc(675.11 / 754 * 100%); // ~89.54%
      transform: rotate(76.82deg) scale(0.8);
    }
  }

  &.is-animated {
    .flower__center {
      opacity: 1;
      left: calc(246 / 790 * 100%); // ~31.14%
      top: calc(207 / 754 * 100%); // ~27.45%
      transform: rotate(0deg) scale(1);
      transition-delay: 0.1s;
    }

    .flower__petal {
      opacity: 1;

      &--1 {
        left: calc(284 / 790 * 100%); // ~35.95%
        top: 0;
        transform: rotate(0deg) scale(1);
        transition-delay: 0.2s;
      }

      &--2 {
        left: calc(476.87 / 790 * 100%); // ~59.86%
        top: calc(105.77 / 754 * 100%); // ~13.99%
        transform: rotate(0deg) scale(1);
        transition-delay: 0.4s;
      }

      &--3 {
        left: calc(438.07 / 790 * 100%); // ~55.45%
        top: calc(317.84 / 754 * 100%); // ~42.15%
        transform: rotate(0deg) scale(1);
        transition-delay: 0.6s;
      }

      &--4 {
        left: calc(238.73 / 790 * 100%); // ~29.97%
        top: calc(450.33 / 754 * 100%); // ~59.73%
        transform: rotate(0deg) scale(1);
        transition-delay: 0.8s;
      }

      &--5 {
        left: 0;
        top: calc(316.99 / 754 * 100%); // ~41.78%
        transform: rotate(0deg) scale(1);
        transition-delay: 1s;
      }

      &--6 {
        left: calc(32.95 / 790 * 100%); // ~4.17%
        top: calc(104.66 / 754 * 100%); // ~13.88%
        transform: rotate(0deg) scale(1);
        transition-delay: 1.2s;
      }
    }
  }
}
</style>
